/* Navbar Component */
/* Navbar Component */
/* Navbar Component */

.navbar-container {
  height: 72px;
  background-color: white;
  /* width: 100%; */
  padding: 0 4vw;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.navbar-container-dash {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-container-dash-max {
  width: 1200px;
  /* display: flex;
  align-items: center; */
}

.navbar-part1 {
  display: flex;
  align-items: center;
  height: 72px;
}

.navbar-part2 {
  display: flex;
  align-items: center;
  /* height: 60px; */
}

.navbar-part2-dashboard {
  display: flex;
  align-items: center;
  /* height: 60px; */
}

@media (max-width: 700px) {
  .navbar-part2-dashboard {
    display: none;
  }
}

@media (max-width: 1300px) {
  .navbar-container-dash-max {
    max-width: 1200px;
    padding: 0 4vw;
  }
}

.navbar-container2 {
  height: 72px;
  background-color: white;
  /* width: 100%; */
  padding: 0 4vw;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-bottom: 1px solid;
  border-color: rgba(245, 236, 228);
}

.navbar-container3 {
  height: 72px;
  background-color: white;
  /* width: 100%; */
  padding: 0 4vw;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.navbar-container3b {
  height: 72px;
  background-color: #2871f6;
  /* width: 100%; */
  padding: 0 4vw;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.navbar-container4b {
  height: 72px;
  background-color: #2871f6;
  /* width: 100%; */
  padding: 0 4vw;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.navbar-container4 {
  height: 72px;
  background-color: white;
  /* width: 100%; */
  padding: 0 4vw;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.link-left {
  margin-right: 32px;
  font-size: 16px;
  font-weight: 500;
}

.link-right {
  margin-left: auto;
  margin-right: 32px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.link-right-dash {
  font-family: 'Bossa';
  margin-left: auto;
  margin-right: 32px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  /* color: #000; */
}

.link-right-dash:hover {
  opacity: 0.7;
}

@media (max-width: 700px) {
  .link-right-dash {
    display: none;
  }
  .link-right-dash2 {
    margin-left: auto;
  }
}

.navbar-cta-btn {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 12px 22px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
}

.navbar-cta-btn-dash {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  font-weight: 400;
  padding: 14px 24px;
  border-radius: 50px;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #e1ff64;
  background-color: #2871f6;
}

.navbar-cta-btn-dash:hover {
  background-color: #222;
}

@media (max-width: 560px) {
  .navbar-cta-btn-dash {
    padding: 8px 12px;
    font-size: 14px;
  }
}

.fauna-logo {
  cursor: pointer;
  margin-top: 4px;
  width: 150px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.fauna-logo2 {
  cursor: pointer;

  /* width: 150px; */
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fauna-logo2 img {
  display: block;
  width: 165px;
}

.fauna-logo-dash {
  cursor: pointer;
  margin-top: 4px;
  width: 150px;
  /* position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center; */
}

.push-left {
  margin-left: auto;
  /* font-size: 16px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-hamburger {
  cursor: pointer;
  border: none;
  text-decoration: none;
  color: white;
  background-color: #fff;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* hamburger active */

.mobile-menu {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 10;
  width: 100%;
  min-height: 100vh;
  padding: 0px 4vw;
  background-color: #dae6fa;
  box-sizing: border-box;
}

.navbar-container-mobile {
  height: 72px;
  /* width: 100%; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 16px;
}

.navbar-cta-btn2 {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 12px 22px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  margin-top: 16px;
}

.mobile-link {
  font-size: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

.navbar-hamburger2 {
  cursor: pointer;
  border: none;
  text-decoration: none;
  color: white;
  background-color: transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 720px) {
  .link-left {
    display: none;
  }
  .link-right {
    display: none;
  }
  .navbar-cta-btn {
    display: none;
  }
  .fauna-logo {
    position: static;
    text-align: left;
    margin: 0;
  }
}

@media (min-width: 720px) {
  .navbar-hamburger {
    display: none;
  }
  .push-left {
    display: none;
  }
}

/* FOOTER Component */
/* FOOTER Component */
/* FOOTER Component */

.section-footer {
  background-color: #000;
  color: white;
  padding: 60px 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-footer-logo {
  cursor: pointer;
  margin-bottom: 32px;
}

.section-footer-logo img {
  display: block;
}

.section-footer-contact {
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 32px;
}

.section-footer-social {
  display: flex;
  margin-bottom: 32px;
}

.section-footer-social-btn {
  border-radius: 100%;
  width: 75px;
  height: 75px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.section-footer-social-btn img {
  display: block;
}

.section-footer-terms {
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}

.section-footer-link {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 400px) {
  .section-footer-social-btn {
    width: 70px;
    height: 70px;
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media (max-width: 280px) {
  .section-footer-social-btn {
    width: 65px;
    height: 65px;
    margin-left: 4px;
    margin-right: 3px;
  }
}

/* CTA Banner Section */
/* CTA Banner Section */
/* CTA Banner Section */

.section-banner {
  background-color: #dae6fa;
  min-height: 34px;
  padding: 6px 4vw;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-text {
  text-align: center;
}

.banner-started {
  margin-left: 8px;
  color: #6b58ca;
  transition: color 250ms;
}
.banner-started:hover {
  color: #f766cb;
}

/* HERO Section */
/* HERO Section */
/* HERO Section */

.section-hero {
  background-color: #fcf9f9;
  padding: 100px 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.section-hero-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.section-hero-left {
  width: 50%;
  position: relative;
  z-index: 1;
}

.section-hero-tagline {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 52px;
  line-height: 135%;
  color: #000000;
  margin-bottom: 32px;
}
.section-hero-bullet {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.section-hero-bullet-icon {
  margin-right: 12px;
  min-height: 24px;
  min-width: 24px;
}
.section-hero-bullet-text {
  font-size: 18px;
  /* font-size: 20px; */
  line-height: 170%;
  letter-spacing: 0.02em;
  color: #000000;
}
.section-hero-btn {
  margin-top: 32px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  padding: 18px 28px;
  /* identical to box height */
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  background: #000000;
  border: none;
  border-radius: 8px;
}

.section-hero-right {
  width: 50%;
  position: relative;
  z-index: 0;
}

.section-hero-img1 {
  /* width: 55vw; */
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -52%);
}
.section-hero-img2 {
  /* width: 55vw; */
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
}
.section-hero-img3 {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-48%, -45%);
}

@media (max-width: 1142px) {
  .section-hero-tagline {
    font-size: 40px;
  }
}

@media (max-width: 865px) {
  .section-hero-container {
    flex-direction: column;
  }
  .section-hero-left {
    width: 70%;
  }
  .section-hero-right {
    margin-top: 350px;
    width: 70%;
  }
  .section-hero-img1 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .section-hero-img2 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .section-hero-img3 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 580px) {
  .section-hero {
    padding-top: 32px;
  }
  .section-hero-tagline {
    font-size: 32px;
    /* text-align: center; */
  }
  .section-hero-left {
    width: 100%;
  }
  .section-hero-btn {
    width: 100%;
  }
  .section-hero-right {
    margin-top: 300px;
    width: 100%;
  }

  .section-hero-img2 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
  }

  .section-hero-img3 {
    top: 50%;
    left: 50%;
    transform: translate(-55%, -50%);
  }
  .section-hero-img3 img {
    transform: scale(0.8);
  }
}

/* Logo Section */
/* Logo Section */
/* Logo Section */

.section-logos {
  background-color: #fff;
  padding: 40px 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-logos-item {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 310px; */
  margin-left: 48px;
  margin-right: 48px;
}
.section-logos-icon {
  margin-right: 12px;
  height: 64px;
}
.section-logos-text {
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.02em;
}

@media (max-width: 1080px) {
  .section-logos-item {
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
  }
  .section-logos-icon {
    margin-right: 0px;
  }
  .section-logos-text {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .section-logos-item {
    margin-left: 12px;
    margin-right: 12px;
  }
  .section-logos-text {
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .section-logos-item {
    margin-left: 4px;
    margin-right: 4px;
  }
  .section-logos-text {
    font-size: 12px;
  }
}

/* HOW Section */
/* HOW Section */
/* HOW Section */

.section-how {
  background-color: #fcf9f9;
  padding: 60px 4vw;
  /* display: flex;
  justify-content: center; */
}

.section-how-title {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 64px;
  text-align: center;
}

.section-how-contain {
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.section-how-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.section-how-item {
  width: 250px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.section-how-icon {
  height: 105px;
  min-width: 80px;
  margin-bottom: 24px;
}

.section-how-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
}

@media (max-width: 900px) {
  .section-how-container {
    flex-direction: column;
    align-items: center;
  }
  .section-how-item {
    margin-top: 40px;
    flex-direction: row;
    width: 75%;
  }
  .section-how-title {
    margin-bottom: 0px;
  }
  .section-how-icon {
    margin-bottom: 0px;
    margin-right: 24px;
  }
  .section-how-text {
    text-align: left;
    margin-bottom: 24px;
  }
}

@media (max-width: 560px) {
  .section-how-item {
    width: 100%;
  }
}

/* MEDICAL Section */
/* MEDICAL Section */
/* MEDICAL Section */

.section-medical {
  height: 540px;
  background-color: #fff;
  padding: 0px 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.section-medical-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.section-medical-left {
  width: 50%;
  position: relative;
  z-index: 0;
}

.section-medical-tagline {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 135%;
  color: #000000;
  margin-bottom: 32px;
}

.section-medical-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 170%;
  letter-spacing: 0.02em;
  color: #525251;
}

.section-medical-right {
  width: 50%;
  max-width: 475px;
  margin-left: 64px;
  position: relative;
  z-index: 1;
}

.section-medical-img1 {
  /* width: 55vw; */
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -45%);
}
.section-medical-img2 {
  /* width: 55vw; */
  position: absolute;
  top: 50%;
  left: 32%;
  transform: translate(-50%, -50%);
}
.section-medical-img3 {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-52%, -55%);
}

@media (max-width: 1000px) {
  .section-medical-container {
    flex-direction: column-reverse;
  }
  .section-medical {
    height: 100%;
    background-color: #fff;
    padding: 60px 4vw 60px;
  }
  .section-medical-tagline {
    font-size: 36px;
  }
  .section-medical-right {
    width: 80%;
    max-width: 600px;
    margin-left: 0px;
  }
  .section-medical-left {
    width: 80%;
    margin-top: 350px;
  }
  .section-medical-img1 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -42%);
  }
  .section-medical-img2 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
  }
  .section-medical-img3 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  }
}

@media (max-width: 560px) {
  .section-medical-tagline {
    font-size: 28px;
  }
  .section-medical-left {
    margin-top: 300px;
  }
  .section-medical-right {
    width: 95%;
  }
  .section-medical-img1 {
    transform: translate(-50%, -35%);
  }
  .section-medical-img2 {
    transform: translate(-50%, -35%);
  }
  .section-medical-img3 {
    transform: translate(-50%, -70%);
  }

  .section-medical-img3 img {
    transform: scale(0.6);
  }
}

/* PRICING Section */
/* PRICING Section */
/* PRICING Section */

.section-pricing {
  background-color: #eaf0fa;
  padding: 80px 4vw;
  /* display: flex;
  justify-content: center; */
}

.section-pricing-title {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 32px;
  text-align: center;
}

.section-pricing-container {
  display: flex;
  justify-content: center;
}
.section-pricing-item {
  margin-left: 20px;
  margin-right: 20px;
  max-width: 344px;
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
}
.section-pricing-item-title {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 32px;
}
.section-pricing-item-price {
  text-align: center;
  font-weight: 900;
  font-size: 64px;
  line-height: 140%;
  letter-spacing: 0.03em;
}
.section-pricing-item-price2 {
  text-align: center;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #979797;
  margin-bottom: 32px;
}

.section-pricing-item-price3 {
  text-align: center;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #979797;
  margin-bottom: 32px;
}

.section-pricing-item-bullet {
  display: flex;
  margin-top: 16px;
}
.section-pricing-item-icon {
  height: 24px;
  min-width: 24px;
  margin-right: 12px;
}
.section-pricing-item-text {
  font-size: 16px;
  line-height: 170%;
  color: #525251;
}

@media (max-width: 720px) {
  .section-pricing-container {
    flex-direction: column;
    align-items: center;
  }
  .section-pricing-item {
    margin-bottom: 32px;
  }
  .section-pricing {
    padding: 80px 4vw 48px;
  }
}

/* BRANDS Section */
/* BRANDS Section */
/* BRANDS Section */

.section-brands {
  background-color: #fff;
  padding: 50px 4vw 30px;
  /* display: flex;
  justify-content: center; */
}

.section-brands-title {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 32px;
  text-align: center;
}

.section-brands-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.section-brands-image {
  margin-left: 12px;
  margin-right: 12px;
}

/* SAYING Section */
/* SAYING Section */
/* SAYING Section */

.section-saying {
  background-color: #f7f7f7;
  padding: 60px 4vw;
}

.section-saying-title {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 32px;
  text-align: center;
}

.section-saying-container {
  display: flex;
  justify-content: center;
}
.section-saying-item {
  margin-left: 20px;
  margin-right: 20px;
  max-width: 350px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
}
.section-saying-item-top {
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}
.section-saying-item-img {
  display: block;
  margin-right: 12px;
}

.section-saying-item-img img {
  display: block;
}
.section-saying-item-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
  color: #525251;
}
.section-saying-item-mid {
  height: 300px;
  background-image: url('/landing/saying-mid.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.section-saying-item-bot {
  /* height: 136px; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding-left: 16px;
  padding-right: 16px;
  padding: 16px;
}
.section-saying-item-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
  color: #525251;
  margin-bottom: 8px;
}
.section-saying-item-sub {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #525251;
}

@media (max-width: 900px) {
  .section-saying-container {
    flex-direction: column;
    align-items: center;
  }
  .section-saying-item {
    margin-top: 32px;
  }
  .section-saying-title {
    margin-bottom: 0px;
  }
}

/* QUOTES Section */
/* QUOTES Section */
/* QUOTES Section */

.section-quotes {
  background-color: #fff;
  padding: 40px 4vw 40px;
}

.section-quotes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-quotes-quote {
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 160%;
  color: #1d1d1d;
  margin-bottom: 16px;
  max-width: 1000px;
}

.section-quotes-image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.section-quotes-image {
  margin-left: 24px;
  margin-right: 24px;
}

.quotes-image {
  display: block;
}

.image-medium {
  margin-top: 8px;
}

@media (max-width: 700px) {
  .section-quotes-quote {
    font-size: 22px;
  }
  .quotes-image {
    transform: scale(0.8);
  }

  .image-medium {
    margin-top: 12px;
  }
}

/* WHY Section */
/* WHY Section */
/* WHY Section */

.section-why {
  background-color: #fcf9f9;
  padding: 60px 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-why-title {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 140%;
  color: #000000;
  text-align: center;
}

.section-why-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.section-why-item {
  width: 430px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 64px;
}
.section-why-logo {
  margin-bottom: 16px;
}
.section-why-logo img {
  display: block;
}

.section-why-text-title {
  font-size: 20px;
  line-height: 140%;
  color: #000000;
  font-weight: bold;
  margin-bottom: 8px;
}
.section-why-text-sub {
  font-size: 16px;
  line-height: 170%;
  letter-spacing: 0.02em;
  color: #525251;
}

@media (max-width: 600px) {
  .section-why-container {
    align-items: center;
  }
  .section-why-item {
    max-width: 430px;
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

/* STARTED Section */
/* STARTED Section */
/* STARTED Section */

.section-started {
  background: linear-gradient(90deg, #ffecff 0%, #fff7df 100%);
  padding: 40px 4vw 40px;
}

.section-started-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-started-cta {
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 160%;
  color: #1d1d1d;
  margin-bottom: 16px;
  max-width: 1000px;
  margin-bottom: 32px;
}

.section-started-btn {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  padding: 18px 28px;
  /* identical to box height */
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  background: #000000;
  border: none;
  border-radius: 8px;
}

@media (max-width: 580px) {
  .section-started-cta {
    font-size: 22px;
  }
  .section-started-btn {
    width: 100%;
  }
}

/* PRIVACY Section */
/* PRIVACY Section */
/* PRIVACY Section */

.section-privacy {
  background-color: #fff;
  padding: 60px 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-privacy-title {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 140%;
  color: #000000;
  text-align: center;
  margin-bottom: 32px;
}

.section-privacy-sub {
  font-size: 16px;
  line-height: 170%;
  text-align: center;
  color: #525251;
  margin-bottom: 64px;
  max-width: 500px;
}

.section-privacy-title2 {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 140%;
  color: #000000;
  text-align: center;
  margin-bottom: 24px;
}

.section-privacy-logo1 {
  margin-bottom: 16px;
}
.section-privacy-logo2 {
  margin-bottom: 24px;
}

.section-privacy-logo1 img {
  display: block;
}
.section-privacy-logo2 img {
  display: block;
}
.section-privacy-logo3 img {
  display: block;
}

/* TEST */
/* TEST */
/* TEST */

.test-login-section {
  background-color: #dae6fa;
  min-height: 34px;
  padding: 6px 4vw;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-section {
  background-color: white;
  padding: 40px 4vw;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 450px;
}

.test-section {
  background-color: #fcf9f9;
  padding: 60px 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.test-upload-btn {
  background: #fff;
  padding: 20px;
  background-color: transparent;
  border: 2px dashed #dac3e4;
  border-radius: 6px;
  cursor: pointer;
  width: 450px;
  min-height: 280px;
  margin-right: auto;
  margin-left: auto;
  transition: background-color 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.test-upload-btn:hover {
  background-color: #f8f0fc;
}

.test-upload-btn-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #979797;
}

.input-hidden {
  visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 0px;
  width: 0px;
}

.test-clickable {
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 30px;
  z-index: 10;
}

.test-input-container {
  position: relative;
}

.test-input {
  background: #fffbf7;
  border: 2px solid #dac4b1;
  padding: 30px;
  border-radius: 5px;
  font-size: 28px;
  height: 20px;
  line-height: 20px;
  font-weight: 700;
  /* max-height: 84px; */
  color: #544742;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}

.navbar-ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  display: flex;
}

.navbar-ul li:hover {
  opacity: 0.8;
}

.navbar-li {
  font-family: 'Bossa';
  margin-right: 20px;
  padding: 14px 0px;
  font-size: 14px;
  cursor: pointer;
  color: #888;
  height: 100%;
}

.navbar-li:hover {
  color: #555;
}

.navbar-li-active {
  font-family: 'Bossa';
  margin-right: 20px;
  padding: 14px 0px;
  font-size: 14px;
  cursor: pointer;
  color: #000;
  height: 100%;
  border-bottom: 3px solid #2871f6;
}
